<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">结算管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">结算单列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 10px">
            <div title="结算单编号" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 6rem">结算单编号:</span>
              <el-input
                v-model="searchData.billNo"
                clearable
                placeholder="结算单编号"
                size="small"
              ></el-input>
            </div>
            <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 6rem">机构名称:</span>
              <el-select
                size="small"
                v-model="searchData.compId"
                remote
                :remote-method="getCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
              >
                <el-option
                  v-for="item in CompanyList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>
            <div title="付款机构名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 8rem"
                >付款机构名称:</span
              >
              <el-input
                v-model="searchData.payerName"
                clearable
                placeholder="付款机构名称"
                size="small"
              ></el-input>
            </div>
          </div>
          <div class="searchbox" style="margin-bottom: 10px">
            <div title="开票状态" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 6rem">开票状态:</span>
              <el-select
                size="small"
                v-model="searchData.billing"
                placeholder="请选择开票状态"
                clearable
              >
                <el-option
                  v-for="item in billingTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="结算单状态" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 6rem">结算单状态:</span>
              <el-select
                size="small"
                v-model="searchData.auditType"
                clearable
                placeholder="请选择收款结算单状态"
              >
                <el-option
                  v-for="item in statusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="有无照片" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 8rem">有无照片:</span>
              <el-select
                size="small"
                v-model="searchData.withImg"
                placeholder="请选择有无照片"
                clearable
              >
                <el-option
                  v-for="item in withImgTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox" style="margin-bottom: 10px">
            <div title="有无原单" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 6rem">有无原单:</span>
              <el-select
                size="small"
                v-model="searchData.withBill"
                placeholder="请选择有无原单"
                clearable
              >
                <el-option
                  v-for="item in withImgTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="创建日期" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 6rem">创建日期:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="searchData.createTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
          </div>
          <div class="searchbox" style="margin-bottom: 5px">
            <div title="收款日期" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 6rem">收款日期:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="searchData.payeeDate"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <div title="收回结算单日期" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 8rem"
                >结算单收回日期:</span
              >
              <el-date-picker
                clearable
                size="small"
                v-model="searchData.billRecycleDate"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()"
              >查询</el-button
            >
            <el-button type="primary" class="bgc-bv" round @click="doExport()"
              >导出</el-button
            >
            <el-button
              type="primary"
              class="bgc-bv"
              round
              @click="applyForInvoicing()"
              >申请开票</el-button
            >
          </div>

          <!--          <div class="searchbox" style="margin-bottom:15px">-->

          <!--            <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>-->
          <!--            <el-button type="primary" class="bgc-bv" round @click="doExport()">导出</el-button>-->
          <!--          </div>-->
        </div>
        <div>
          <span style="padding: 8px 10px"
            >合计付款金额:{{ sumAmount || 0 }}元</span
          >
          <span style="padding: 8px 10px"
            >合计结算人数:{{ sumCount || 0 }}人</span
          >
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTables"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              row-key="billId"
              style="width: 100%"
              @selection-change="checkListData"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                type="selection"
                width="50px"
                align="center"
                fixed
                :reserve-selection="true"
                :selectable="selectable"
              ></el-table-column>
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
              />
              <el-table-column
                label="结算单编号"
                align="left"
                show-overflow-tooltip
                prop="billNo"
                fixed
                min-width="180px"
              />
              <el-table-column
                label="机构名称"
                align="left"
                show-overflow-tooltip
                prop="compName"
                min-width="250px"
              />
              <el-table-column
                label="付款机构名称"
                align="left"
                show-overflow-tooltip
                prop="payerName"
                min-width="250px"
              />
              <el-table-column
                label="付款金额"
                align="left"
                show-overflow-tooltip
                prop="payerAmount"
                min-width="100px"
              />
              <el-table-column
                label="优惠后金额"
                align="left"
                show-overflow-tooltip
                prop="settlementAmount"
                min-width="100px"
              />
              <el-table-column
                label="结算人数"
                align="left"
                show-overflow-tooltip
                prop="settlementUserCount"
                min-width="100px"
              />
              <el-table-column
                label="有无照片"
                align="left"
                show-overflow-tooltip
                prop="withImg"
                min-width="80px"
              />
              <el-table-column
                label="有无原单"
                align="left"
                show-overflow-tooltip
                prop="withBill"
                min-width="80px"
              />
              <el-table-column
                label="收款日期"
                align="left"
                show-overflow-tooltip
                prop="payeeDate"
                min-width="150px"
              >
                <template slot-scope="scope">
                  {{ scope.row.payeeDate | momentDate }}
                </template>
              </el-table-column>
              <el-table-column
                label="到账金额"
                align="left"
                show-overflow-tooltip
                prop="arrivalAmount"
                min-width="100px"
              />
              <el-table-column
                label="开票金额"
                align="left"
                show-overflow-tooltip
                prop="invoiceFee"
                min-width="120px"
              />
              <el-table-column
                label="开票状态"
                align="left"
                show-overflow-tooltip
                prop="billing"
                min-width="100px"
              >
                <template slot-scope="scope">
                  <span>
                    {{ $setDictionary("IS_INVOICE_STATE", scope.row.billing) }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                label="开票时间"
                align="left"
                show-overflow-tooltip
                prop="invoiceDate"
                min-width="150px"
              >
                <template slot-scope="scope">
                  {{ scope.row.invoiceDate | momentDate }}
                </template>
              </el-table-column>
              <el-table-column
                label="合同是否收回"
                align="left"
                show-overflow-tooltip
                min-width="120px"
                prop="isTakeBack">
                    <template v-slot="{row}">
                        {{row.isTakeBack?"是":"否"}}
                    </template>
              </el-table-column>

              <el-table-column
                label="收回合同日期"
                align="left"
                show-overflow-tooltip
                min-width="120px"
                prop="takeBackDate">
              </el-table-column>


              <el-table-column
                label="结算单状态"
                align="center"
                show-overflow-tooltip
                prop="auditType"
                max-width="300px"
                min-width="100px"
              >
                <template slot-scope="scope">
                  <span>
                    {{ $setDictionary("FD_AUDIT_TYPE", scope.row.auditType) }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                label="结算单收回日期"
                align="left"
                show-overflow-tooltip
                prop="billRecycleDate"
                min-width="150px"
              >
                <template slot-scope="scope">
                  {{ scope.row.billRecycleDate | momentDate }}
                </template>
              </el-table-column>
              <el-table-column
                label="创建日期"
                align="left"
                show-overflow-tooltip
                prop="createTime"
                min-width="150px"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="允许下载档案"
                align="center"
                show-overflow-tooltip
                min-width="120px"
                fixed="right"
              >
                <template slot-scope="scope">
                  <el-switch
                    v-model="scope.row.haveAllowed"
                    @change="allowDownloadStatement(scope.row, scope.$index)"
                  >
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="300px"
                fixed="right"
              >
                <div slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    :disabled="
                      scope.row.auditType == '10' ||
                      scope.row.auditType == '20' ||
                      scope.row.auditType == '30'
                    "
                    @click="preview(scope.row.billId)"
                    >预览
                  </el-button>
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    :disabled="
                      !(
                        scope.row.billType == '10' &&
                        (scope.row.billSource == '20' ||
                          scope.row.billSource == '30')
                      )
                    "
                    @click="removeBill(scope.row.billId)"
                    >删除
                  </el-button>
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    :disabled="
                      scope.row.auditType == '10' ||
                      scope.row.auditType == '20' ||
                      scope.row.auditType == '30' ||
                      scope.row.auditType == '53' ||
                      scope.row.auditType == '56' ||
                      scope.row.auditType == '90'
                    "
                    @click="downloadBill(scope.row.billId)"
                    >下载结算单
                  </el-button>
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="uploadFile(scope.row.billId)"
                    >上传附件
                  </el-button>
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="lookuploadfile(scope.row.billId)"
                    >查看附件</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
        <div class="Batchs">
          <div class="BatchBox">
            <div class="BatchBoxFirst">
              <p>已选结算单</p>
              <p>（{{ backArr.length }}）</p>
            </div>
            <div class="BatchBoxSecond ovy-a">
              <div class="BatchforBox">
                <div
                  v-for="(item, index) in backArr"
                  :key="index"
                  class="BatchforFlex"
                >
                  <div class="BatchImgBox" @click="deleteImg(item)">
                    <img
                      :src="require('@/assets/close_.png')"
                      alt=""
                      @mouseover="transToWhite(index, $event)"
                      @mouseleave="transToBlack(index, $event)"
                    />
                  </div>
                  <span style="padding-left: 5px">{{ item.billNo }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 预览 - loading -->
    <el-dialog
      title="预览"
      :visible.sync="previewLoding2"
      width="50%"
      top="2%"
      center
    >
      <div class="ovy-a">
        <div
          id="pdf-cert"
          style="height: 600px"
          v-if="docTypeing == 'PDF' || docTypeing == 'pdf'"
        ></div>
        <div v-else>
          <img :src="srcs" alt="" width="100%" height="100%" />
        </div>
      </div>
    </el-dialog>
    <!-- 预览 - loading -->
    <el-dialog
      title="预览"
      :visible.sync="previewLoding"
      width="50%"
      top="2%"
      center
    >
      <div style="height: 580px" class="ovy-a">
        <div
          id="pdf-cert"
          style="height: 600px"
          v-if="docTypeing == 'PDF' || docTypeing == 'pdf'"
        ></div>
        <div v-else>
          <img :src="srcs" alt="" width="100%" height="100%" />
        </div>
        <div class="el-div"></div>
        <h3 style="padding-bottom: 20px">流转记录</h3>
        <div>
          <el-table
            ref="multipleTable"
            :data="circulationRecordList"
            size="small"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="tableHeader"
            stripe
          >
            <el-table-column
              label="审核时间"
              align="center"
              show-overflow-tooltip
              prop="createTime"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.createTime | moment }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="审核人"
              align="center"
              show-overflow-tooltip
              prop="fullname"
            />
            <el-table-column
              label="审核状态"
              align="center"
              show-overflow-tooltip
              prop="auditType"
            >
              <template slot-scope="scope">
                <span>
                  {{
                    $setDictionary(
                      "FD_NEW_AUDIT_TYPE_RECORD",
                      scope.row.auditType
                    )
                  }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              label="备注"
              align="center"
              show-overflow-tooltip
              prop="remark"
            >
              <template slot-scope="scope">
                {{ scope.row.remark || "--" }}
              </template>
            </el-table-column>

            <el-table-column
              label="操作"
              align="center"
              show-overflow-tooltip
              prop="amountReceivable"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="mini"
                  style="padding: 0 5px"
                  :disabled="!scope.row.url"
                  @click="lookupfileUrl2(scope.row)"
                  >查看</el-button
                >
              </template>
            </el-table-column>
            <Empty slot="empty" />
          </el-table>
        </div>
      </div>
    </el-dialog>
    <!-- 上传附件 - loading -->
    <el-dialog
      title="上传附件"
      :visible.sync="uploadfiledialog"
      width="35%"
      class="toVoidLoding"
    >
      <el-radio-group v-model="docType">
        <el-radio label="10">结算单照片</el-radio>
        <el-radio label="20">结算单原单</el-radio>
        <el-radio label="99">其他</el-radio>
      </el-radio-group>
      <div style="margin-bottom: 15px">
        <el-input
          type="textarea"
          placeholder="请输入备注"
          v-model="fileremark"
          maxlength="255"
          show-word-limit
        >
        </el-input>
      </div>
      <div style="margin: 10 0 10px">
        <div>
          <el-upload
            class="upload-demo upload-btn upload-btndrag"
            :action="actionUrl"
            :on-error="handleError"
            :on-success="handleSuccess"
            :on-change="uploadChange1"
            :show-file-list="false"
            :auto-upload="false"
            drag
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
          </el-upload>
        </div>
        <div
          v-if="uploadfileList.length"
          style="margin-top: 50px; margin-left: 10px"
        >
          当前选择文件：
          <div style="display: flex; flex-direction: column">
            <span
              style="color: #409eff"
              v-for="(item, index) in uploadfileList"
              :key="index"
            >
              {{ item.fileName }}
              <!-- <a @click="reomveExl" style="color: #5bb5ff;margin:0 15px">预览</a> -->
              <a @click="lookExl(item)" style="color: #409eff; margin: 0 15px"
                >预览</a
              >
              <a @click="reomveExl(index)" style="color: red; margin: 0 15px"
                >删除</a
              >
            </span>
          </div>
        </div>
        <div v-else style="margin-top: 50px; margin-left: 10px">
          <p style="margin-top: 10px; margin-left: 10px">未选择文件</p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          size="small"
          class="bgc-bv"
          round
          @click="uploadfileClose"
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          class="bgc-bv"
          round
          @click="uploadfileDetermine"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 上传附件预览 - loading -->
    <el-dialog
      title="预览"
      :visible.sync="uploadLoding"
      @close="uploadLodClose"
      width="50%"
      top="2%"
      center
    >
      <div class="ovy-a">
        <div
          id="pdf-cert2"
          style="height: 600px"
          v-if="fileType == 'pdf' || fileType == 'PDF'"
        ></div>
        <div v-else>
          <img :src="ImgSrc" alt="" width="100%" height="100%" />
        </div>
      </div>
    </el-dialog>
    <!-- 查看附件 -->
    <el-dialog title="查看附件" :visible.sync="lookfiledialog">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="uploadfileList"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeader"
          stripe
        >
          <el-table-column label="序号" align="center" type="index" />
          <el-table-column
            label="姓名"
            align="center"
            show-overflow-tooltip
            prop="operatorName"
          />
          <el-table-column
            label="名称"
            align="center"
            show-overflow-tooltip
            prop="docType"
          >
            <template slot-scope="scope">
              {{
                scope.row.docType == "10"
                  ? "结算单照片"
                  : scope.row.docType == "20"
                  ? "结算单原单"
                  : "其他"
              }}
            </template>
          </el-table-column>
          <el-table-column
            label="上传日期"
            align="center"
            show-overflow-tooltip
            min-width="180"
            prop="createTime"
          >
            <template slot-scope="scope">
              {{ scope.row.createTime | moment }}
            </template>
          </el-table-column>
          <el-table-column
            label="备注"
            align="center"
            show-overflow-tooltip
            prop="remark"
          />
          <el-table-column
            label="操作"
            align="center"
            show-overflow-tooltip
            prop="amountReceivable"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                style="padding: 0 5px"
                :disabled="!scope.row.fileUrl"
                @click="lookupfileUrl(scope.row)"
                >查看</el-button
              >
            </template>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </el-dialog>
    <el-dialog
      title="预览"
      :visible.sync="fileUrldiaLog2"
      width="50%"
      top="2%"
      center
    >
      <div class="ovy-a">
        <div
          id="pdf-cert1"
          style="height: 600px"
          v-if="fileType == 'pdf' || fileType == 'PDF'"
        ></div>
        <div v-else>
          <img :src="ImgSrc" alt="" width="100%" height="100%" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import pdf from "pdfobject";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";

export default {
  name: "evaluate/contractManagement",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      //查询条件
      searchData: {
        billNo: "",
        compId: "",
        payerName: "",
      },
      CompanyList: [],
      previewLoding: false,
      billingTypeList: [],
      withImgTypeList: [
        {
          label: "有",
          value: true,
        },
        {
          label: "无",
          value: false,
        },
      ],
      dialogVisible: false,
      remark: "",
      listData: [],
      centerDialogVisible: false,
      fileName: "",
      fileKey: "",
      errorCount: "0",
      projectCount: "0",
      successCount: "0",
      taskIds: [],
      statusList: [], //结算单状态list
      payerAmountSum: 0,
      settlementUserCountSum: 0,
      NameList: [],
      previewLoding1: false,
      fileUrldiaLog2: false,
      uploadfiledialog: false, //上传附件弹框
      docType: "10", //文档类型
      fileremark: "", //上传附件备注
      uploadfileList: [], //上传附件
      uploadLoding: false,
      billId: "",
      toVoidLoding: false,
      lookfiledialog: false,
      previewLoding2: false,
      sumAmount: 0,
      sumCount: 0,
      docTypeing: "",
      circulationRecordList: [],
      fileType: "",
      ImgSrc: "",
      // 列表勾选的数据
      multipleTable: [],
      // 同步列表勾选的底部数据
      backArr: [],
    };
  },
  watch: {},
  created() {},
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() {
    this.yesNokp();
    this.getTableHeight();
    this.getStatusList();
  },
  methods: {
    // 获取 - 收款结算单状态下拉数据
    getStatusList() {
      const statusList = this.$setDictionary("FD_AUDIT_TYPE", "list");
      const list = [];
      for (const key in statusList) {
        // &&key != "20"
        list.push({
          value: key,
          label: statusList[key],
        });
      }
      this.statusList = list;
    },
    // 获取 - 是否开票下拉数据
    yesNokp() {
      const statusList = this.$setDictionary("IS_INVOICE_STATE", "list");
      const list = [];
      for (const key in statusList) {
        // &&key != "20"
        list.push({
          value: key,
          label: statusList[key],
        });
      }
      this.billingTypeList = list;
    },

    // 获取 - 机构名称
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        billing: this.searchData.billing,
        withImg: this.searchData.withImg,
        withBill: this.searchData.withBill,
      };
      if (this.searchData.billNo) {
        params.billNo = this.searchData.billNo;
      }
      if (this.searchData.compId) {
        params.compId = this.searchData.compId;
      }
      if (this.searchData.payerName) {
        params.payerName = this.searchData.payerName;
      }
      if (this.searchData.auditType) {
        params.auditType = this.searchData.auditType;
      }
      if (this.searchData.payeeDate) {
        params.payeeDateStart = this.searchData.payeeDate[0];
        params.payeeDateEnd = this.searchData.payeeDate[1];
      }
      if (this.searchData.billRecycleDate) {
        params.billRecycleDateStart = this.searchData.billRecycleDate[0];
        params.billRecycleDateEnd = this.searchData.billRecycleDate[1];
      }
      if (this.searchData.createTime) {
        params.createTimeStart = this.searchData.createTime[0];
        params.createTimeEnd = this.searchData.createTime[1];
      }
      this.doFetch({
        url: "/biz/new/bill/statement/pageList",
        params,
        pageNum,
      });
      this.$post("/biz/new/bill/statement/totalBill", params)
        .then((ret) => {
          this.sumCount = ret.data.sumCount;
          this.sumAmount = ret.data.sumAmount;
        })
        .catch((err) => {
          return;
        });
    },
    //弹框关闭
    doCancle() {
      this.remark = "";
      this.dialogVisible = false;
    },
    // 导出
    doExport() {
      const params = {
        billing: this.searchData.billing,
        withImg: this.searchData.withImg,
        withBill: this.searchData.withBill,
      };
      if (this.searchData.compId) {
        params.compId = this.searchData.compId;
      }
      if (this.searchData.billNo) {
        params.billNo = this.searchData.billNo;
      }
      if (this.searchData.payerName) {
        params.payerName = this.searchData.payerName;
      }
      if (this.searchData.auditType) {
        params.auditType = this.searchData.auditType;
      }
      if (this.searchData.payeeDate) {
        params.payeeDateStart = this.searchData.payeeDate[0];
        params.payeeDateEnd = this.searchData.payeeDate[1];
      }
      if (this.searchData.createTime) {
        params.createTimeStart = this.searchData.createTime[0];
        params.createTimeEnd = this.searchData.createTime[1];
      }
      if (this.searchData.billRecycleDate) {
        params.billRecycleDateStart = this.searchData.billRecycleDate[0];
        params.billRecycleDateEnd = this.searchData.billRecycleDate[1];
      }
      this.$post("/biz/new/bill/statement/export", params)
        .then((res) => {
          console.log(res);
          if (res.status == "0") {
            let list = res.data;
            for (let item of list) {
              if (!this.downloadItems.includes(item.taskId)) {
                this.$store.dispatch("pushDownloadItems", item.taskId);
              } else {
                this.$message.warning(
                  "[" + item.fileName + "]已经申请下载,请耐心等待"
                );
              }
            }
          } else {
            this.$message.error(re.message);
          }
        })
        .catch((err) => {
          return;
        });
    },
    // 结算单列表 - 勾选列表数据
    checkListData(rowArr) {
      this.multipleTable = rowArr;
      this.backArr = rowArr;
    },
    // 结算单列表 - 鼠标移入关闭样式
    transToWhite(index, e) {
      e.target.src = require("@/assets/error.png");
    },
    // 结算单列表 - 鼠标离开关闭样式
    transToBlack(index, e) {
      e.target.src = require("@/assets/close_.png");
    },
    // 结算单列表 - 删除底部已选
    deleteImg(itemobj) {
      let newArr = this.backArr.filter((item) => item.billId != itemobj.billId);
      this.backArr = newArr;
      this.multipleTable.find((el, index) => {
        if (el.billId == itemobj.billId) {
          delete this.multipleTable[index];
          this.$refs.multipleTable.toggleRowSelection(el, false);
        }
      });
    },
    // 申请开票
    applyForInvoicing() {
      if (this.multipleTable.length > 0) {
        if (new Set(this.multipleTable.map((t) => t.payerName)).size > 1) {
          this.$message({
            message: "不同付款机构不能申请开票",
            type: "error",
          });
          return;
        }
        this.$router.push({
          path: "/web/financialSettlement/invoicingManagementAddEdit",
          query: {
            settlementInfoData: JSON.stringify(this.multipleTable),
            stu: "billState",
            applicationComp: "10",
          },
        });
      } else {
        this.$message({
          message: "请至少勾选一项!",
          type: "warning",
        });
      }
    },
    // 下载结算单
    downloadBill(billId) {
      this.$post("/biz/new/bill/statement/download", { billId })
        .then((res) => {
          if (res.status == "0") {
            window.open(res.message);
            this.getData(-1)
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          return;
        });
    },
    // 预览
    preview(billId) {
      this.previewLoding = true;
      this.docTypeing = "pdf" || "PDF";
      this.$post("/biz/new/audit/circulation", { billId })
        .then((ret) => {
          this.circulationRecordList = ret.data;
        })
        .catch((err) => {
          return;
        });
      this.$post("/biz/new/bill/review", { billId })
        .then((ret) => {
          this.$nextTick(() => {
            var options = {
              pdfOpenParams: {
                pagemode: "thumbs",
                toolbar: 0,
                navpanes: 0,
                statesbar: 0,
                view: "FitV",
                scrollbars: "0",
              },
            };
            pdf.embed(ret.message, "#pdf-cert", options);
          });
        })
        .catch((err) => {
          return;
        });
    },
    //查看附件
    lookuploadfile(billId) {
      this.billId = billId;
      this.lookfiledialog = true;
      this.getfileList(billId);
    },
    //上传附件
    uploadFile(billId) {
      this.uploadfiledialog = true;
      this.billId = billId;
      this.getfileList(billId);
    },
    getfileList(billId) {
      this.$post("/biz/new/bill/billFileList", { billId })
        .then((ret) => {
          this.uploadfileList = ret.data.fdNewBillFileBOList || [];
          this.fileremark = ret.data.remark || "";
          console.log(this.fileremark);
        })
        .catch((err) => {
          return;
        });
    },
    lookupfileUrl(row) {
      this.previewLoding2 = true;
      this.docTypeing = row.fileType;
      if (row.fileType == "pdf" || row.fileType == "PDF") {
        this.$nextTick(() => {
          pdf.embed(row.fileUrl, "#pdf-cert");
        });
      } else {
        this.srcs = row.fileUrl;
      }
    },
    lookupfileUrl2(row) {
      this.fileUrldiaLog2 = true;
      if (row.fileType == "pdf" || row.fileType == "PDF") {
        this.$nextTick(() => {
          pdf.embed(row.url, "#pdf-cert1");
        });
      } else {
        this.ImgSrc = row.url;
      }
    },
    reomveExl(index) {
      this.uploadfileList.splice(index, 1);
      this.$forceUpdate();
    },
    lookExl(item) {
      this.uploadLoding = true;
      this.fileType = item.fileType;
      if (item.fileType == "pdf" || item.fileType == "PDF") {
        this.$nextTick(() => {
          pdf.embed(item.fileUrl, "#pdf-cert2");
        });
      } else {
        this.ImgSrc = item.fileUrl;
      }
    },
    uploadLodClose() {
      this.uploadLoding = false;
      this.fileType = "";
      this.ImgSrc = "";
    },
    uploadChange1(file) {
      this.fileName = file.name;
      let size = file.size / 1024 / 1024;
      let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "PDF";
      const isxlsx = extension === "pdf";
      const jpg = extension === "jpg";
      const png = extension === "png";
      // const isXLS = extension === "XLS";
      // const isxls = extension === "xls";
      if (!isXLSX && !isxlsx && !jpg && !png) {
        this.$message.error("只能上传后缀是.pdf或.PDF或.jpg或.png的文件");
        return;
      }
      if (size > 10) {
        this.$message.error("文件大小不能超过10M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);

      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.uploadfileList.push({
            billId: this.billId,
            fileName: file.name,
            fileKey: result.data.fileKey,
            fileType: extension,
            fileUrl: result.data.fileURL,
          });
        })
        .catch((err) => {
          return;
        });
    },
    uploadfileClose() {
      this.uploadfiledialog = false;
      this.fileremark = "";
      this.uploadfileList = [];
    },
    // 上传附件 - 确定
    uploadfileDetermine() {
      let parmar = {
        billId: this.billId,
        remark: this.fileremark,
        docType: this.docType,
      };
      parmar.fileList = this.uploadfileList;
      if (this.fileremark == "" && this.docType == "99") {
        this.$message({
          message: "请输入备注",
          type: "error",
        });
        return;
      }
      this.$post("/biz/new/bill/saveFiles", parmar)
        .then((ret) => {
          if (ret.status == "0") {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.uploadfiledialog = false;
            this.fileremark = "";
            this.uploadfileList = [];
            this.getData(-1);
          }
        })
        .catch((err) => {
          return;
        });
    },
    // 删除
    removeBill(billId) {
      this.doDel({
        url: "/biz/new/bill/statement/remove",
        msg: "你确定要删除当前结算单吗？",
        ps: {
          type: "post",
          data: { billId },
        },
      });
    },
    // 允许下载结算单
    allowDownloadStatement(row, index) {
      this.$post("/biz/new/bill/statement/project/print", {
        billId: row.billId,
        allowPrint: row.haveAllowed,
      })
        .then((res) => {
          if (res.status == "0") {
            this.$message({
              type: "success",
              message: res.message,
            });
          }
        })
        .catch((err) => {
          return;
        });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 15) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    // 监听缓存后调用接口
    $route: {
      handler: function (route) {
        if (route.query.refresh) {
          this.getData();
          this.$refs.multipleTables.clearSelection();
        }
        // this.activeName = route.params.refName;
      },
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
.dialog-footer {
  margin-top: 0.75rem;
  display: flex;
  justify-content: flex-end;
}
.Batchs {
  padding: 1rem 1rem;
  width: 100%;
  .BatchBox {
    display: flex;
    height: 70px;
    border: 1px solid #ebeef5;
    border-radius: 5px;
    .BatchBoxFirst {
      width: 260px;
      background: #f2f7fd;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .BatchBoxSecond {
      width: 100%;
      .BatchforBox {
        display: flex;
        flex-wrap: wrap;
        .BatchforFlex {
          display: flex;
          align-items: center;
          padding: 5px 20px;
          cursor: pointer;
          .BatchImgBox {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          img {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}
</style>